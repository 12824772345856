<template lang="pug">
include ../pug/svg
section#account
	nav.navbar.sticky
		div
			ul
				li(v-if="hasFeature('account','profile')")
					router-link(:to="{name:'ManageAccountProfile'}") Profile
				li(v-if="hasFeature('account','organisation')")
					router-link(:to="{name:'ManageAccountOrganisation'}") {{terminology('organisation','organisation','singular')}}
				li(v-if="usrSeniority<=20")
					router-link(:to="{name:'ManageAccountFeature'}") Features
				//-li
					router-link(:to="{name:'ManageAccountBilling'}") Billing
			button.but.pri.spinner(:class="{spin:saving}" type="button" :disabled="saving||!canSave" @click="saveAccount") Save
				+svg(svg-filename="spinner" aria-hidden="true" alt="Processing")
			button.but.pri.spinner(:class="{spin:resetting}" type="button" :disabled="resetting||!canCancel" @click="resetAccount") Cancel
				+svg(svg-filename="spinner" aria-hidden="true" alt="Processing")
			
	ModalConfirm(:active="leaveTo" :useDrag="true" @cancel="leaveTo=null" @confirm="resetAccount")
	router-view(v-slot="{Component}")
		transition(name="section" mode="out-in" enter-active-class="hide" leave-active-class="hide" appear)
			component(:is="Component" :cancelled="cancelled" @storeUpdated="storeUpdated($event)")
</template>

<script>
import UserService from '../services/UserService';
import ModalConfirm from '../components/ModalConfirm';
import OrganisationService from '../services/OrganisationService';

export default {
	name: 'ManageAccount',
	components: {
		ModalConfirm,
	},
	data() {
		return {
			updated: {
				profile: null,
				organisation: null,
				feature: null,
			},
			canSave: false,
			canCancel: false,
			cancelled: false,
			saving: false,
			resetting: false,
//			saved: false,
			leaveTo: null,
		}
	},
	beforeRouteLeave (to, from, next) {
		// unsaved changes
		if (this.canSave && !this.leaveTo) {
			this.leaveTo = to;
			this.$store.dispatch('gui/setTintState', true);
			next(false);
			
		} else {
			next();			
		}
	},
	computed: {
		usrSeniority() {
			return this.$store.getters['auth/getUserSeniority'];
		},
	},
	methods: {
		hasFeature(feature, subfeature) {
			return this.$store.getters['gui/hasFeature'](feature, subfeature);
		},
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		/*storeUpdated({valid}) {
			if (!this.cancelled) {
				// changes made in child component, so enable/disable save button based of validity of change
				this.canSave = valid;
				this.canCancel = true;				
			}
			// get view triggering update
			// const view = this.$router.currentRoute.value.name;			
		},*/
		storeUpdated({screen, valid}) {
			// log screen updated for efficient save
			this.updated[screen] = valid;
			
			let validSet = null;
			
			for(let screen in this.updated) {
				if (this.updated[screen] === false) {
					validSet = false;
					
				} else if (this.updated[screen] === true && validSet !== false) {
					validSet = true;
				}
			}
			
			// enable save button
			this.canSave = validSet ? true : false;
			this.canCancel = this.canSave;
		},
		async resetAccount() {
			this.cancelled = true;
			this.canSave = false;
			this.canCancel = false;
			this.resetting = true;
			
			const delay = this.$store.getters['gui/delay'];			
			
			// reset auth user from db
			await this.$store.dispatch('auth/status');
			
			// reset organisation from db
			await this.$store.dispatch('gui/initOrganisation');
			
			await delay;
			
			// disable save until changes
			this.resetFlags();
		},
		async saveAccount() {
			try {
				this.saving = true;
				const delay = this.$store.getters['gui/delay'];
				
				if (this.updated.profile) {
					// copy store to local var for cleanse
					const user = {...this.$store.state.auth.user};
					
					user.zoomUserEmail = user.userOrganisations[0].zoomUserEmail;
					
					delete user.userOrganisations;
					delete user.userId;
					
					// save user to db
					await UserService.updateUser(user);
					
					// update auth user from db update
					await this.$store.dispatch('auth/status');
				}
				
				if (this.updated.organisation || this.updated.feature) {
					// save organisation to db
					const organisation = this.$store.state.gui.organisation;
					
					await OrganisationService.updateBySubdomain({
						name: organisation.name,
						websiteUrl: organisation.websiteUrl,
						emailGeneral: organisation.emailGeneral,
						emailSales: organisation.emailSales,
						emailSupport: organisation.emailSupport,
						phoneGeneral: organisation.phoneGeneral,
						phoneSales: organisation.phoneSales,
						phoneSupport: organisation.phoneSupport,
						featureData: this.$store.state.gui.feature,
					});
				}
				
				await delay;
				
				this.resetFlags();
				
			} catch(err) {
				// handle error
				console.log('saveAccount()>err:', err.response.status, err.response.data.message);
//				actions.setFieldError('name', err.response.data.message);
			}
		},
		resetFlags() {
			// reset
			this.updated.profile = null;
			this.updated.organisation = null;
			this.updated.feature = null;
			this.canSave = false;
			this.canCancel = false;
			this.saving = false;
			this.resetting = false;
		},
	},
}
</script>

<style lang="scss">
</style>
