<template lang="pug">
include ../pug/svg
div.modal.confirm.narrow.center(:class="{active:active}")
	ModalBar(label="Confirmation" :useDrag="useDrag" @close="cancel()")
	div.body
		p.small {{message}}
	div.foot
		button.but.pri(@click="confirm()") {{labelConfirm}}
		button.but.sec(@click="cancel()") {{labelCancel}}
</template>

<script>
import ModalBar from '../components/ModalBar';

export default {
	name: 'ModalConfirm',
	props: {
		active: {
			type: [Boolean, Object], // router object
			default() {
				return false;
			}
		},
		message: {
			type: String,
			required: false,
			default: 'This action will discard all unsaved changes.',
		},
		labelConfirm: {
			type: String,
			required: false,
			default: 'Confirm',
		},
		labelCancel: {
			type: String,
			required: false,
			default: 'Cancel',
		},
		useDrag: {
			type: Boolean,
			default: false
		},	
	},
	emits: ['confirm', 'cancel'],
	components: {
		ModalBar,
	},
	/*watch: {
		active: function(val) {
			this.$store.dispatch('gui/setTintState', (val));
		},
	},*/
	methods: {
		confirm() {
			this.untint();
			this.$emit('confirm');
			
			if (typeof this.active !== 'boolean') {
				// router object
				this.$router.push({name:this.active.name, params:this.active.params});
			}
		},
		cancel() {
			this.untint();
			this.$emit('cancel');
		},
		untint() {
			this.$store.dispatch('gui/setTintState', false);
		},
	},
}
</script>

<style lang="scss">
</style>
