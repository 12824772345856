<template lang="pug">
include ../pug/svg
div.bar(:class="{drag:useDrag}" @mousedown="dragStart")
	+svg(svg-filename="iconMore" aria-hidden="true")
	span {{label}}
	button.but(v-if="useClose" type="button" @click="close") Close
		+svg(svg-filename="iconClose")
</template>

<script>
export default {
	name: 'ModalBar',
	props: {
		label: {
			type: String,
			required: false,
			default: '',
		},
		useClose: {
			type: Boolean,
			default: true
		},	
		useDrag: {
			type: Boolean,
			default: false
		},	
	},
	emits: ['close'],
	data() {
		return {
			drag: {
				el: null,
				clientX: null,
				clientY: null,
				moveX: 0,
				moveY: 0,
			}
		};
	},
	methods: {
		close() {
			this.$emit('close');
		},
		dragStart: function (event) {
			if (!this.useDrag || event.target.nodeName === 'BUTTON') {
				return;
			}
			
			event.preventDefault();
			const el = event.target.closest('.modal');
			
			if (el) {	
				this.drag.el = el;
				this.drag.clientX = event.clientX;
				this.drag.clientY = event.clientY;
				document.addEventListener('mousemove', this.dragMove);
				document.addEventListener('mouseup', this.dragEnd);
			}
		},
		dragMove: function (event) {
			event.preventDefault();
			
			this.drag.moveX = this.drag.clientX - event.clientX;
			this.drag.moveY = this.drag.clientY - event.clientY;
			this.drag.clientX = event.clientX;
			this.drag.clientY = event.clientY;
			this.drag.el.style.top = (this.drag.el.offsetTop - this.drag.moveY) + 'px';
			this.drag.el.style.left = (this.drag.el.offsetLeft - this.drag.moveX) + 'px';
		},
		dragEnd() {
			document.removeEventListener('mousemove', this.dragMove);
			document.removeEventListener('mouseup', this.dragEnd);
		},	
	},
}
</script>

<style lang="scss">
</style>
